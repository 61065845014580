.previewContainerStyle {
  display: block;
  width: 120px;
  padding: 10px;
  padding-top: 15px;
  border: 1px solid #f4f4f4;
  margin-right: 20px;
  text-align: center;
}

.previewContainerStyle span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uploadBtn { 
    border-radius: 5px;
    padding: 15px;
    border-color: #f2faff;
    background-color: #f2faff;
    height: initial;
    min-height: 100px;
    min-width: 110px;
    margin-right: 20px;
}

.uploadBtn:focus, .uploadBtn:hover { 
    border-color: #f2faff;
    background-color: #f2faff;
}

.excelTable {
    margin-top: 20px;
    margin-left: -65px;
}

.uploadSpin .ant-spin-dot {
  font-size: 18px !important;
}
